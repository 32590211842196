import React from "react";
import { Link, Grid } from "@mui/material";

const Menu = ({ isMobile, isTinyWindow }) => {
  return (
    <Grid
      container
      direction="row"
      style={{
        padding: "0 5px 0 5px",
        marginBottom: "30px",
        fontSize: isMobile ? "20px" : "30px",
        color: "white",
        width: isTinyWindow() ? "50%" : "100%",
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Link
        href="https://opensea.io/collection/teknomancers"
        color="inherit"
      >
        STORY
      </Link>
      <Link href="https://w3bbie.xyz/" color="inherit">
        TEAM
      </Link>
      <Link
        href="https://discord.gg/3nDbCTBy"
        color="inherit"
      >
        Discord
      </Link>
    </Grid>
  );
};

export default Menu;
