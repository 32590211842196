import "./App.css";
import React, { useState } from "react";
import { Button, Grid, Link } from "@mui/material";
import MintCounter from "./MintCounter2";
import Menu from "./Menu";
import Media from "./Media";
import SocialFabs from "./SocialFabs";
import { ethers, BigNumber } from "ethers";
import title from "./logo.png";
import contractABI from "./abi.json";
const contractAddress = "0x70D87a24236DBfCd09B4a18393f4393Cf522e063";
const mintPrice = 0.001;
const contractLink = `https://etherscan.io/address/${contractAddress}`;

const MainMint = ({
  accounts,
  setAccounts,
  isMobile,
  setHashLink,
  setShowAlert,
  setShowAlertError,
  isTinyWindow,
}) => {
  const isConnected = Boolean(accounts[0]);
  const [mintCount, setMintCount] = useState(1);

  function getTransactionLink(hash) {
    return `https://etherscan.io/tx/${hash}`;
  }

  async function connectAccount() {
    if (window.ethereum) {
      const _accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(_accounts);
    }
  }

  async function mint() {
    if (window.ethereum) {
      console.log("handleMint");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        contractABI.abi,
        signer
      );

      try {
        let { hash } = await contract.mint(BigNumber.from(mintCount), {
          value: ethers.utils.parseEther((mintPrice * mintCount).toString()),
        });

        let hashLink = getTransactionLink(hash);
        setHashLink(hashLink);
        setShowAlert(true);
      } catch (err) {
        //console.log("error: ", err.message);
        //alert(err.message);
        setShowAlertError(true);
      }
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        textAlign: "center",
        padding: 0,
      }}
    >
      {/*<h1 className={isMobile() ? "TitleMobile" : "Title"}>FOUND ON MARS</h1>*/}

      <img
        src={title}
        style={{
          width: isTinyWindow() ? "95vw" : "60vw",
          height: "auto",
          padding: 0,
          marginBottom: "20px",
          marginTop: "50px",
        }}
      />

      <Menu isMobile={isMobile()} isTinyWindow={isTinyWindow} />
      {isMobile() ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "50%", padding: "20px" }}
        >
          <Media />
        </Grid>
      ) : null}

      <MintCounter
        mintCount={mintCount}
        setMintCount={setMintCount}
        isConnected={isConnected}
        connectAccount={connectAccount}
        mint={mint}
        isMobile={isMobile}
      />

      {/*
      <Button
        color="secondary"
        onClick={(e) => {
          isConnected ? mint() : connectAccount();
        }}
        variant="contained"
        style={{ fontSize: "20px", backgroundColor: "#DA0503" }}
      >
        {isConnected ? "Mint Now" : "Connect Wallet"}
      </Button>
      */}
      <div className="ContractAddressHeading">Contract Address</div>
      <div className="ContractAddress">
        <Link sx={{ color: "white" }} href={contractLink}>
          {contractAddress}
        </Link>
      </div>
      <SocialFabs />
    </Grid>
  );
};

export default MainMint;
